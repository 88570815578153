<template>
  <div class="mx-3">
    <h2 class="custom-title-secondary">O que você precisa?</h2>
    <selectOptionsCard
      v-model="formUser.typeOfRegistration.value"
      :optionsToChoice="choices"
      :isValid="isValid"
      @onAnswer="onAnswer($event)"
      :rules="[(v) => !!v || 'Selecione uma opcão']"
    ></selectOptionsCard>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import selectOptionsCard from '@components/registering/selectOptionsCard.vue'

export default {
  components: {
    selectOptionsCard,
  },
  props: {
    answer: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
  },
  data() {
    return {
      currentAnswer: this.answer,
      choices: [
        {
          label: 'Abrir uma empresa',
          value: 1,
        },
        {
          label:
            'Já tenho CNPJ e quero trazer minha contabilidade para o Simplificador',
          value: 2,
        },
        {
          label:
            'Tenho um MEI, mas quero transfomá-lo em Empresa Individual do Simples Nacional',
          value: 3,
          info: 'https://simplificador.com.br/blog/transformando-mei-em-me/',
        },
        {
          label: 'Contabilidade para autônomo ou profissional liberal',
          value: 6,
        },
      ],
    }
  },
  methods: {
    onAnswer(value) {
      this.formUser.typeOfRegistration.value = value
      this.$emit('onAnswer', {
        typeOfRegistration: this.formUser.typeOfRegistration.value,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@design';
</style>
